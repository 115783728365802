<template>
    <div>
        <!-- Filter Section -->
        <b-card title="Filters" class="mb-0">
          <b-form @submit.prevent>
            <b-row>
               <!-- Year  -->
               <b-col cols="12" md="2">
                <b-form-group
                    :label="$t('globalSingular.period')"
                    label-for="year"
                  >
                        <v-select
                            inputId="year"
                            class="select-size-sm"
                            v-model="form.year"
                            :options="LOV.year"
                          />
                </b-form-group>
               </b-col>
               <!-- agent or office id -->
               <b-col cols="12" md="2">
                <b-form-group
                  :label="$t('globalSingular.agent')"
                  label-for="office"
                >
                  <v-select
                    class="select-size-sm"
                    v-model="form.officeId"
                    :options="LOV.offices"
                    :reduce="field => field.id"
                    label="label"
                  />
                </b-form-group>
              </b-col>
                <!-- Button Filter -->
                <b-col cols="12" md="2">
                    <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
                        {{ $t('globalActions.find') }}
                    </b-button>
                </b-col>
            </b-row>
          </b-form>
        </b-card>
        <!-- bawah filters  -->
        <!-- button print  -->
        <div class="d-flex justify-content-end mt-1 mb-1">
          <b-button variant="outline-primary" @click="print">
            <feather-icon icon="FileIcon" />
            {{ $t('globalActions.print') }}
          </b-button>
          <b-button-group class="ml-1">
            <b-dropdown right variant="outline-primary">
                 <template #button-content>
                   <feather-icon icon="DownloadIcon"/>
                   {{ $t('globalActions.export') }}
                 </template>
                <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
                <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>
            </b-dropdown>
          </b-button-group>
        </div>
        <!-- Table Container Card -->
        <b-card no-body id="pdfDom">
            <b-card-body class="text-center">
              <b-card-title>{{ companyName }}</b-card-title>
              <b-card-sub-title>
                <div>{{ $t('apps.reportSummaryCHBACC.singular.report') }}</div>
                <div class="mt-sm-1">{{ periodReportText }}</div>
              </b-card-sub-title>
            </b-card-body>

            <!-- table  -->
            <b-table
                id="refListTable"
                ref="refListTable"
                no-provider-paging
                no-provider-filtering
                :filter-included-fields="columnToBeFilter"
                :fields="tableColumns"
                :items="itemLists"
                :filter="searchQuery"
                responsive
                primary-key="id"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
            >
            <template #table-busy>
                <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
                  <div class="text mb-1">
                    <b-spinner class="align-middle"/>
                  </div>
                  <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
                </div>
            </template>

            <template #cell(periode)="{ item }">
                <span :class="['d-block', 'text-nowrap', (item.agent === 'Total') ? 'font-weight-bolder' : '' ]">
                    {{ item.year}}-{{ item.month}}
                </span>
            </template>

             <template #cell(ch_ar)="{ item }">
                <span :class="['d-block', 'text-nowrap', (item.agent === 'Total') ? 'font-weight-bolder' : '', (item.ch_ar > 0) ? 'text-success' : 'text-danger' ] ">
                   <div v-if="item.ch_ar < 0 && item.ch_ar !== 0">
                          {{ parseFloat(item.ch_ar * -1) }}
                   </div>
                   <div v-else>
                          {{ parseFloat(item.ch_ar) }}
                   </div>
                </span>
            </template>

            <template #cell(ch_ap)="{ item }">
                <span :class="['d-block', 'text-nowrap', (item.agent === 'Total') ? 'font-weight-bolder' : '', 'text-danger' ]">
                  <div v-if="item.ch_ap < 0 && item.ch_ap !== 0">
                        ( {{ parseFloat(item.ch_ap * -1) }} )
                   </div>
                   <div v-else>
                          {{ parseFloat(item.ch_ap) }}
                   </div>
                </span>
            </template>

             <template #cell(total_ar_ap)="{ item }">
                <span :class="['d-block', 'text-nowrap', (item.agent === 'Total') ? 'font-weight-bolder' : '', 'text-danger' ]">
                   <div v-if="item.total_ar_ap < 0 && item.total_ar_ap !== 0">
                        ( {{ parseFloat(item.total_ar_ap * -1) }} )
                   </div>
                   <div v-else>
                          {{ parseFloat(item.total_ar_ap) }}
                   </div>
                </span>
            </template>

            <template #cell(total_ict)="{ item }">
                <span :class="['d-block', 'text-nowrap', (item.agent === 'Total') ? 'font-weight-bolder' : '', 'text-danger' ]">
                  <div v-if="item.total_ict < 0 && item.total_ict !== 0">
                        ( {{ parseFloat(item.total_ict * -1) }} )
                   </div>
                   <div v-else>
                          {{ parseFloat(item.total_ict) }}
                   </div>
                </span>
            </template>

            <template #cell(total_brand)="{ item }">
                <span :class="['d-block', 'text-nowrap', (item.agent === 'Total') ? 'font-weight-bolder' : '', 'text-success' ]">
                  <div v-if="item.total_brand < 0 && item.total_brand !== 0">
                        ( {{ parseFloat(item.total_brand * -1) }} )
                   </div>
                   <div v-else>
                          {{ parseFloat(item.total_brand) }}
                   </div>
                </span>
            </template>

            <template #cell(total_ch)="{ item }">
                <span :class="['d-block', 'text-nowrap', (item.agent === 'Total') ? 'font-weight-bolder' : '', (item.total_ch > 0) ? 'text-success' : 'text-danger' ]">
                  <div v-if="item.total_ch < 0 && item.total_ch !== 0">
                        ( {{ parseFloat(item.total_ch * -1) }} )
                   </div>
                   <div v-else>
                          {{ parseFloat(item.total_ch) }}
                   </div>
                </span>
            </template>

            <template #cell(total_bacc)="{ item }">
                <span :class="['d-block', 'text-nowrap', (item.agent === 'Total') ? 'font-weight-bolder' : '', (item.total_bacc > 0) ? 'text-success' : 'text-danger' ]">
                    <div v-if="item.total_bacc < 0 && item.total_bacc !== 0">
                        ( {{ parseFloat(item.total_bacc * -1) }} )
                   </div>
                   <div v-else>
                          {{ parseFloat(item.total_bacc) }}
                   </div>
                </span>
            </template>

            </b-table>

        </b-card>
    </div>
</template>
<script>
import {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onMounted, ref } from '@vue/composition-api'
import useListTable from '@/comp-functions/useListTable'
import { formatCurrency, unformatNumber } from '@/utils/formatter'
import useFormResource from '@/comp-functions/useFormResource'


export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItemButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BSpinner
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    }
  },
  setup () {
    const { get } = useFormResource({ url: false })

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const periodReportText = ref()
    const form = ref({
                    year: '2022',
                    officeId: null
                })
    const LOV = ref({
    year: [
            2021,
            2022,
            2023,
            2024
          ],
    offices: []

    })

    const columnToBeFilter = ['agent']

    // Table Handlers
    const tableColumns = [
      { key: 'agent', label: 'Agent' },
      { key: 'periode', label: 'Periode' },
      { key: 'inv_ch_no', label: 'Invoice CH No' },
      { key: 'ch_ar', label: 'Total AR' },
      { key: 'ch_ap', label: 'Total AP' },
      { key: 'inv_r_ict', label: 'Invoice Fee ICT' },
      { key: 'total_ict', label: 'Fee ICT' },
      { key: 'inv_r_brand', label: 'Invoice Fee Brand' },
      { key: 'total_brand', label: 'Fee Brand' },
      { key: 'total_ar_ap', label: 'Total(AR AP)' },
    //   { key: 'total_ch', label: 'Total CH' },
      { key: 'total_bacc', label: 'BACC' },
      { key: 'total', label: 'Total' }
    ]

    const getReport = async () => {
        const params = [
            `year=${form.value.year}`,
            `officeId=${form.value.officeId}`
          ]
      const url = `reports/summary/chbacc?${ params.join('&') }`
      await fetchLists(url)
    }

    const getOffice = async () => {
      LOV.value.offices = await get({ url: 'master/contact?type={CTAG}'})
    }

    onMounted(async () => {
      getReport()
      getOffice()
    })

    return {
        form,
        LOV,
        periodReportText,
        getReport,
        tableColumns,
        isBusy,
        itemLists,
        perPage,
        currentPage,
        totalList,
        dataMeta,
        perPageOptions,
        searchQuery,
        isSortDirDesc,
        refListTable,
        statusFilter,
        fetchLists,
        columnToBeFilter,
        formatCurrency
    }
  },
  methods: {
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell) => {
            return unformatNumber(cell);
          })
        });
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', '', '', '', ''],
            [`${this.$t('apps.reportPurchaseInvoice.singular.purchaseInvoiceReport')}`, '', '', '', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', '', '', '', ''],
            ['', '', '', '', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 9, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 9, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 9, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 9, r: 3 } }
          ],
          filename: `${this.$t('apps.reportPurchaseInvoice.singular.purchaseInvoiceReport')} ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.select-size-md .vs__dropdown-toggle {
  height: 39px;
}
.dropdown-menu .dropdown-item {
    width: 100%;
  }

  /*#pdfDom td, #pdfDom th {*/
  /*  padding: 0.72rem;*/
  /*}*/

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }
    }
  }

  @page {
    size: auto;
  }

</style>
